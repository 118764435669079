import React from "react";
import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {  UserRequirementDetailReducer } from "../features/Requirements/RequirementSlice";
import { useEffect } from "react";
import moment from "moment";
import currencies from "currency-formatter";

function RequirementModal({ show, handleShow, id }) {
  const dispatch = useDispatch();
  const detail =  useSelector((state) => state.Requirement.details);

  const getdetail = useCallback(() => {
    dispatch(
        UserRequirementDetailReducer({
        id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let isrander = false;
    if (!isrander) {
      getdetail();
    }

    return () => {
      isrander = false;
    };
  }, [getdetail]);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleShow();
      }}
      centered
      size="xl"
    >
      <Modal.Body>
        <div className="" role="document">
          <div className="">
            <div className="table-responsive">
              <table className="table storetable text-center">
                <thead className="">
                  <tr>
                    <th scope="col">UPREFID</th>
                    <th scope="col">Number Plates</th>
                    <th scope="col">Body Types</th>
                    <th scope="col">Min Price</th>
                    <th scope="col">Max Price</th>
                    <th scope="col">Brands</th>
                    <th scope="col">Age Of Car</th>
                    <th scope="col">Fuel Types</th>
                    <th scope="col">Transmissions</th>
                    <th scope="col">Registration State</th>
                    <th scope="col">Location Preferred</th>
                    <th scope="col">Loan Required</th>
                    <th scope="col">How Soon You Want To Buy</th>
            
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>#{detail?.id}</td>
                    <td>{detail?.number_plates || "N/A"}</td>
                    <td>{detail?.body_types || "N/A"}</td>
                    <td>
                      {currencies.format(detail?.min_price, {
                        code: "INR",
                        symbol: "INR ",
                      })}
                    </td>
                    <td>
                      {currencies.format(detail?.max_price, {
                        code: "INR",
                        symbol: "INR ",
                      })}
                    </td>
                    <td>{detail?.brands || "N/A"}</td>
                    <td>{detail?.age_of_car === "0" ? 'Below 5 Years Old' : detail?.age_of_car === "1" ? 'Above 5 Years Old' : 'N/A'}</td>
                    <td>{detail?.fuel_types || "N/A"}</td>
                    <td>{detail?.transmissions}</td>
                    <td>{detail?.registration_state}</td>
                    <td>{detail?.location_preferred}</td>
                    <td>{detail?.loan_required ? 'Yes' : 'No' || 'N/A'}</td>
                    <td>{detail?.how_soon_you_want_to_buy == 0 ? '1-2 W' : detail?.how_soon_you_want_to_buy == 1 ? '2-4 W' : '4+ W'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default RequirementModal;
