import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddQuotation from "../../../component/AddQuotation";
import ViewImageModal from "../../../component/ViewImageModal";
import { RTOServicesDetailReducer } from "./RTOServicesSlice";

function RTOServicesRequests() {
  const [ReqId, setReqId] = useState("");
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [imgSRC, setimgSRC] = useState("");
  const [img, setimg] = useState({});
  const [imgName, setimgName] = useState("");
  const handleShow = () => {
    setShow(!show);
  };
  const { id } = useParams();
  const dispatch = useDispatch();

  const detail = useSelector((store) => store.RTOServices.details);
  //console.log(detail);

  const getdetail = useCallback(() => {
    dispatch(
      RTOServicesDetailReducer({
        id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let isrander = false;
    if (!isrander) {
      getdetail();
    }

    return () => {
      isrander = false;
    };
  }, [getdetail]);
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h2 className="head-title">RTO Services</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-4 text-center">
              <img
                src={
                  detail?.serviceDetails?.image || "assets/images/employe.png"
                }
                className="img-fluid srvsIcon"
                alt=""
              />
            </div>
            <div className="col-sm-12 mt-4">
              <h2>User Detail</h2>
            </div>

            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="table-responsive pt-4">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="assets/images/Full-Name.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          First Name
                        </td>
                        <td>
                          <img
                            src="assets/images/Full-Name.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Last Name
                        </td>
                        <td>
                          <img
                            src="assets/images/mobile-nu.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Mobile Number{" "}
                        </td>
                        <td>
                          <img
                            src="assets/images/Email-Address.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          personal Email
                        </td>
                        <td>
                          <img
                            src="assets/images/Email-Address.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          official email
                        </td>
                      </tr>
                      <tr>
                        <th>{detail?.userDetails?.first_name || "NA"}</th>
                        <th>{detail?.userDetails?.last_name || "NA"}</th>
                        <th>
                          {detail?.userDetails?.mobile
                            ? `${detail?.userDetails?.countryCode} ${detail?.userDetails?.mobile}`
                            : "NA"}
                        </th>
                        <th>{detail?.userDetails?.personal_email || "NA"}</th>
                        <th>{detail?.userDetails?.official_email || "NA"}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-sm-12 mb-4">
              <h2>Vehicle Detail</h2>
            </div>
            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="table-responsive pt-4">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="assets/images/REGN-No-Initials.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Vehicle Regn No
                        </td>
                        <td>
                          <img
                            src="assets/images/OEM-Make.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Make{" "}
                        </td>
                        <td>
                          <img
                            src="assets/images/Model.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Modal
                        </td>
                        <td>
                          <img
                            src="assets/images/Fuel.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Fuel
                        </td>
                        <td>
                          <img
                            src="assets/images/Month-Year-of-Regn.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Regn Year
                        </td>
                        <td>
                          <img
                            src="assets/images/Current-Car-Location.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          Loction
                        </td>
                      </tr>
                      <tr>
                        <th>{detail?.vehicle_regn_no || "NA"}</th>
                        <th>{detail?.make || "NA"}</th>
                        <th>{detail?.model || "NA"}</th>
                        <th>{detail?.fuel || "NA"}</th>
                        <th>{detail?.regn_year || "NA"}</th>
                        <th>{detail?.vehicle_location || "NA"}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-sm-12 mb-4">
              <h2>Required Images</h2>
            </div>
            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">RC Front Image</h3>
                    <img
                      src={
                        detail?.documentDetails?.rc_front_image ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setModalName("ViewImage");
                        setimgSRC("documentDetails.rc_front_image");
                        setimgName(detail?.documentDetails?.rc_front_image_name || "rc_front_image");
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">RC Back Image</h3>
                    <img
                      src={
                        detail?.documentDetails?.rc_back_image ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setimgSRC("documentDetails.rc_back_image");
                        setModalName("ViewImage");
                        setimgName(detail?.documentDetails?.rc_back_image_name || "rc_back_image");
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 mt-4">
                    <h2>Seller ID & Address</h2>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">Seller ID</h3>
                    <img
                      src={
                        detail?.rtoDetails?.seller_id_file_path ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setModalName("ViewImage");
                        handleShow();
                        setimgSRC("rtoDetails.seller_id_file_path");
                        setimgName(detail?.rtoDetails?.seller_id_file_name || "seller_id_file_name");
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">Seller Address</h3>
                    <img
                      src={
                        detail?.rtoDetails?.seller_address_file_path ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setimgSRC("rtoDetails.seller_address_file_path");
                        setimgName(detail?.rtoDetails?.seller_address_file_name || "seller_address_file_name");
                        setModalName("ViewImage");
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 mt-4">
                    <h2>Buyer ID & Address</h2>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">Buyer ID</h3>
                    <img
                      src={
                        detail?.rtoDetails?.buyer_id_file_path ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setimgSRC("rtoDetails.buyer_id_file_path");
                        setimgName(detail?.rtoDetails?.buyer_id_file_name || "buyer_id_file_name");
                        setModalName("ViewImage");
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <h3 className="mb-2 pt-3 theme-color">Buyer Address</h3>
                    <img
                      src={
                        detail?.rtoDetails?.buyer_address_file_path ||
                        "assets/images/employe.png"
                      }
                      className="img-fluid idImages"
                      alt=""
                      onClick={() => {
                        setimgSRC("rtoDetails.buyer_address_file_path");
                        setimgName(detail?.rtoDetails?.buyer_address_file_name  || "buyer_address_file_name");
                        setModalName("ViewImage");
                        handleShow();
                      }}
                    />
                  </div>
                  <div className="col-sm-12 mt-4">
                    <h2>Jobs to be done</h2>
                  </div>
                  <div className="col-sm-6 col-md-3 col-lg-2 mb-3">
                    <p> Name transfer</p>
                    <img
                      src={
                        detail?.rtoDetails?.is_name_transfer === true
                          ? "assets/images/yes.png"
                          : detail?.rtoDetails?.is_name_transfer === false
                          ? "assets/images/no.png"
                          : "assets/images/na-img.png"
                      }
                      width="20px"
                      alt=""
                    />
                  </div>
                  <div className="col-sm-6 col-md-3 col-lg-2 mb-3">
                    <p> CNG Add</p>
                    <img
                      src={
                        detail?.rtoDetails?.is_cng_add === true
                          ? "assets/images/yes.png"
                          : detail?.rtoDetails?.is_cng_add === false
                          ? "assets/images/no.png"
                          : "assets/images/na-img.png"
                      }
                      width="20px"
                      alt=""
                    />
                  </div>
                  <div className="col-sm-6 col-md-3 col-lg-2 mb-3">
                    <p> NOC</p>
                    <img
                      src={
                        detail?.rtoDetails?.is_noc === true
                          ? "assets/images/yes.png"
                          : detail?.rtoDetails?.is_noc === false
                          ? "assets/images/no.png"
                          : "assets/images/na-img.png"
                      }
                      width="20px"
                      alt=""
                    />
                  </div>
                  <div className="col-sm-6 col-md-3 col-lg-2 mb-3">
                    <p> HP Add</p>
                    <img
                      src={
                        detail?.rtoDetails?.is_hp_add === true
                          ? "assets/images/yes.png"
                          : detail?.rtoDetails?.is_hp_add === false
                          ? "assets/images/no.png"
                          : "assets/images/na-img.png"
                      }
                      width="20px"
                      alt=""
                    />
                  </div>
                  <div className="col-sm-6 col-md-3 col-lg-2 mb-3">
                    <p> HP Delete</p>
                    <img
                      src={
                        detail?.rtoDetails?.is_hp_delete === true
                          ? "assets/images/yes.png"
                          : detail?.rtoDetails?.is_hp_delete === false
                          ? "assets/images/no.png"
                          : "assets/images/na-img.png"
                      }
                      width="20px"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 mb-4">
              <h2>Payment Detail</h2>
            </div>
            <div className="col-sm-12 mb-4">
              <div className="view-detail mt-0">
                <div className="table-responsive pt-4">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="assets/images/REGN-No-Initials.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          amount
                        </td>
                        <td>
                          <img
                            src="assets/images/Model.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          transaction Id
                        </td>
                        <td>
                          <img
                            src="assets/images/OEM-Make.png"
                            className="detail-icon"
                            alt=""
                          />{" "}
                          order Id{" "}
                        </td>
                      </tr>
                      <tr>
                        <th>{detail?.paymentDetails?.amount || "NA"}</th>
                        <th>{detail?.paymentDetails?.transactionId || "NA"}</th>
                        <th>{detail?.paymentDetails?.orderId || "NA"}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="view-btns">
                {detail?.quotation_pdf === null ? (
                  <button
                    className="btn btn-reject btn-view mt-2"
                    onClick={() => {
                      setReqId(detail?.id);
                      setModalName("AddQuotation");
                      handleShow();
                    }}
                  >
                    Add Quotation
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {show && modalName === "AddQuotation" && (
          <AddQuotation
            show={show}
            handleShow={handleShow}
            ReqId={ReqId}
            getList={getdetail}
          />
        )}
        {detail[imgSRC.split(".")[0]] &&
          imgSRC &&
          modalName === "ViewImage" && (
            <ViewImageModal
              show={show}
              handleShow={handleShow}
              imgSRC={imgSRC}
              image={detail}
              setitem={setimg}
              setimgSRC={setimgSRC}
              imageName={imgName}
            />
          )}
      </article>
    </>
  );
}

export default RTOServicesRequests;
