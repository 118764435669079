import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AddQuotation from "../../../component/AddQuotation";
import Paginations from "../../../component/Pagination";
import { HelpSupportServiceListingsReducer } from "./HelpSupportServiceSlice";

function HelpSupportService() {
  const dispatch = useDispatch();
  const total = useSelector((state) => state.HelpSupportService.total);
  const data = useSelector(
    (state) => state.HelpSupportService.HelpSupportService
  );
  const [page, setPage] = useState(1);
  const [ReqId, setReqId] = useState("");
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const handleShow = () => {
    setShow(!show);
  };

  const handlePage = (value) => {
    setPage(value);
  };
  const getlist = useCallback(async () => {
    dispatch(
      HelpSupportServiceListingsReducer({
        limit: 10,
        page: page,
      })
    );
  }, [dispatch, page]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlist();
    }
    return () => {
      mounted = false;
    };
  }, [getlist]);
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active">
              <img src="assets/images/AlliedServices.png" alt="" />
              <span>
                <p>{total && total}</p>
                <p>Total Requests</p>
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-12">
            <div className="table-responsive text-center">
              <table className="table storetable">
                <thead className="text-center">
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col" className="whiteSpace">
                      user name
                    </th>
                    <th scope="col" className="whiteSpace">
                      user phone
                    </th>
                    <th scope="col" className="whiteSpace">
                      query related to
                    </th>
                    <th scope="col">description of issue</th>
                    <th scope="col">action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {data && data?.length > 0 ? (
                    data?.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td className="whiteSpace">
                          {item?.userDetails?.first_name
                            ? `${item?.userDetails?.first_name} ${item?.userDetails?.last_name}`
                            : "NA"}
                        </td>
                        <td className="whiteSpace">
                          {item?.userDetails?.mobile
                            ? `${item?.userDetails?.countryCode} ${item?.userDetails?.mobile}`
                            : "NA"}
                        </td>
                        <td className="whiteSpace">
                          {item?.queryDetails?.name || "NA"}
                        </td>
                        <td>{item?.description || "NA"}</td>
                        <td>
                          <div className="d-flex justify-content-center action">
                            <button className="btn" title="View">
                              <Link
                                to={`/help-support-service-requests/${item?.id}`}
                              >
                                <img src="assets/images/view.png" alt="" />
                              </Link>
                            </button>
                            {/* <button
                              className="btn"
                              title="Quotation"
                              onClick={() => {
                                setReqId(item?.id);
                                setModalName("AddQuotation");
                                handleShow();
                              }}
                            >
                              <img src="assets/images/Quotation.png" alt="" />
                            </button> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10">No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {data?.length > 0 && (
          <Paginations
            handlePage={handlePage}
            page={page}
            total={total && total}
          />
        )}
      </div>
      {show && modalName === "AddQuotation" && (
        <AddQuotation
          show={show}
          handleShow={handleShow}
          ReqId={ReqId}
          getList={getlist}
        />
      )}
    </article>
  );
}

export default HelpSupportService;
