import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  CarDetailReducer,
  DeleteCarReducer,
  UpdateCarReducer,
} from "./CarListingsSlice";
import moment from "moment";
import currencies from "currency-formatter";
import DeleteModal from "../../component/DeleteModal";
import { togglesuccess } from "../login/LoginSlice";

import CarImageDownload from "../../component/CarImageDonwload";
function CarDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const success = useSelector((store) => store.login.success);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleShow = () => {
    setShow(!show);
  };

  const detail = useSelector((store) => store.carlistings.details);
  //console.log(detail);

  const getdetail = useCallback(() => {
    dispatch(
      CarDetailReducer({
        id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let isrander = false;
    if (!isrander) {
      getdetail();
    }

    return () => {
      isrander = false;
    };
  }, [getdetail]);

  const Slider = () => {
    return (
      <Carousel
        showArrows={true}
        showIndicators={false}
        showThumbs={false}
        autoPlay
      >
        {detail?.images?.map((item, i) => {
          return (
            <div key={i}>
              <img src={item?.path} alt={item?.name} />
                <CarImageDownload imageUrl={item?.path} imageName={item?.name} />
            </div>
          );
        })}
      </Carousel>
    );
  };
  const approvedReject = (data) => {
    //console.log(data);
    dispatch(UpdateCarReducer(data)).then((res) => {
      if (res?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(res?.payload?.message);

        setTimeout(() => {
          navigate("/car-listings", {
            replace: true,
          });
          setError("");
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(res?.payload?.message);

        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const confirmDelete = () => {
    dispatch(DeleteCarReducer({ car_id: id })).then((res) => {
      if (res?.payload?.code === 1) {
        dispatch(togglesuccess(true));
        setError(res?.payload?.message);
        setTimeout(() => {
          navigate("/car-listings", {
            replace: true,
          });
          setError("");
        }, 2000);
      } else {
        dispatch(togglesuccess(false));
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <h2 className="head-title">New Vehicle Detail</h2>
            <div className="user-id">
              <span>
                Vehicle UID:{" "}
                <span style={{ color: "#fec00f" }}>{detail?.vehicle_UID}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mb-4">
            <div className="view-detail">
              <div className="view-user-img text-center">
                <Slider />
              </div>
              <div className="table-responsive">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <img
                          src="assets/images/OEM-Make.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        vehical Name
                      </td>
                      <td>
                        <img
                          src="assets/images/Price.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        price
                      </td>
                      <td>
                        <img
                          src="assets/images/OEM-Make.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        OEM/Make{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/Model.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Modal
                      </td>
                      <td>
                        <img
                          src="assets/images/Model.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Variant
                      </td>
                      <td>
                        <img
                          src="assets/images/Month-Year-of-Regn.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Month/Year Of Regn
                      </td>
                    </tr>
                    <tr>
                      <th>{detail?.brandDetails?.name}</th>
                      <th>
                        {currencies.format(detail?.price, {
                          code: "INR",
                          symbol: "INR ",
                        })}
                      </th>
                      <th>{detail?.brandDetails?.name}</th>
                      <th>{detail?.modelDetails?.name}</th>
                      <th>{detail?.variantDetails?.name}</th>
                      <th>
                        {moment(detail?.reg_year, "MM/YYYY").format("MMM YYYY")}
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4" colSpan="6">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/REGN-No-Initials.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        REGN No Initials
                      </td>
                      <td>
                        <img
                          src="assets/images/password.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        owener serial
                      </td>
                      <td>
                        <img
                          src="assets/images/Fuel.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Fuel{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/Gear-Type.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Gear Type
                      </td>
                      <td>
                        <img
                          src="assets/images/KM-Reading.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        KM Reading
                      </td>
                      <td>
                        <img
                          src="assets/images/Price.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        price
                      </td>
                    </tr>
                    <tr>
                      <th>{detail?.regn_number?.replace(/.(?!.{6})/g, "x")}</th>
                      <th>{detail?.owner_serial}</th>
                      <th>{detail?.fuel_type}</th>
                      <th>{detail?.transmission}</th>
                      <th>{detail?.km_meter_reading || "00"} km</th>
                      <th>{detail?.price_type}</th>
                    </tr>
                    <tr>
                      <td className="py-4" colSpan="6">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      {/* <td>
                        <img
                          src="assets/images/Running-Loan-on-Vehicle.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Running Loan on Vehicle
                      </td> */}
                      <td>
                        <img
                          src="assets/images/REGN-No-Initials.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        number plate{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/Special-Badge.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Special Badge{" "}
                      </td>
                      <td colSpan="">
                        <img
                          src="assets/images/Special-Badge.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Certification
                      </td>
                      <td colSpan="3">
                        <img
                          src="assets/images/Current-Car-Location.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Current Car Location
                      </td>
                    </tr>
                    <tr>
                      {/* <th>
                        <img
                          src={
                            detail?.running_loan__vehicle === "yes"
                              ? "assets/images/yes.png"
                              : detail?.running_loan__vehicle === "no"
                              ? "assets/images/no.png"
                              : detail?.running_loan__vehicle === "not sure"
                              ? "assets/images/not-readable.png"
                              : "na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th> */}
                      <th>{detail?.number_plate}</th>
                      <th>{detail?.is_price_dropped}</th>
                      <th>
                        <div className="">
                          {detail?.isReviewed ? (
                            <img
                              src="assets/images/circle_tick.png"
                              width="18px"
                              alt=""
                              className="m-auto"
                            />
                          ) : (
                            ""
                          )}{" "}
                          {detail?.isReviewed ? "Seen by Us" : "N/A"}
                        </div>
                      </th>
                      <th colSpan="3">{detail?.address}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-sm-12 mb-4">
            <h2>More about this car</h2>
          </div>
          <div className="col-sm-12 mb-4">
            <div className="view-detail mt-0">
              <h3 className="mb-5 pt-3 theme-color">Particulars</h3>
              <div className="table-responsive">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">Regn Details</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/REGN-No-Initials.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        REGN No Initials
                      </td>
                      <td>
                        <img
                          src="assets/images/Current-Car-Location.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Regn city
                      </td>
                      <td>
                        <img
                          src="assets/images/Current-Car-Location.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Vehical location city{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/password.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        owner serial
                      </td>
                      <td>
                        <img
                          src="assets/images/Month-Year-of-Regn.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        comprehensive insurance validity
                      </td>
                      <td>
                        <img
                          src="assets/images/REGN-No-Initials.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Number plate
                      </td>
                    </tr>
                    <tr>
                      <th>{detail?.regn_number?.replace(/.(?!.{6})/g, "x")}</th>
                      <th>{detail?.regnCityDetails?.city_name}</th>
                      <th>{detail?.address}</th>
                      <th>{detail?.owner_serial}</th>
                      <th>
                        {detail?.comprehensive_insurance_validity || "NA"}
                      </th>
                      <th>{detail?.number_plate}</th>
                    </tr>
                    <tr>
                      <td className="py-4" colSpan="6">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/Engine-repaired-ever.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Engine CC
                      </td>
                      <td>
                        <img
                          src="assets/images/password.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        regd owner name
                      </td>
                      <td>
                        <img
                          src="assets/images/Full-Name.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        my relation with title owner of this car{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/Running-Loan-on-Vehicle.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        loan HP exists on RC
                      </td>
                      <td>
                        <img
                          src="assets/images/Engine-repaired-ever.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Engine number
                      </td>
                      <td>
                        <img
                          src="assets/images/Chasis-Engine-Tracing-match.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Chasis number
                      </td>
                    </tr>
                    <tr>
                      <th>{detail?.engine_cc}</th>
                      <th>{detail?.regd_owner_name}</th>
                      <th>{detail?.relation_with_title_owner}</th>
                      <th>
                        <img
                          src={
                            detail?.loan_hp_status === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loan_hp_status === "no"
                              ? "assets/images/no.png"
                              : detail?.loan_hp_status === "not sure"
                              ? "assets/images/not-readable.png"
                              : "na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>{detail?.engine_number}</th>
                      <th>{detail?.chasis_number}</th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">Model Details</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/Month-Year-of-Regn.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Month/Year of Regn
                      </td>
                      <td>
                        <img
                          src="assets/images/Month-Year-of-Regn.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Month/Year of mfg
                      </td>
                      <td>
                        <img
                          src="assets/images/OEM-Make.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        OEM/make{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/Model.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Model{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/Model.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        varient{" "}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {moment(detail?.reg_year, "MM/YYYY").format("MMM YYYY")}
                      </th>
                      <th>
                        {moment(detail?.mfg_year, "MM/YYYY").format("MMM YYYY")}
                      </th>
                      <th>{detail?.brandDetails?.name}</th>
                      <th>{detail?.modelDetails?.name}</th>
                      <th>{detail?.variantDetails?.name}</th>
                    </tr>
                    <tr>
                      <td className="py-4" colSpan="6">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/OEM-Make.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        body shape
                      </td>
                      <td>
                        <img
                          src="assets/images/Fuel.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        fuel
                      </td>
                      <td>
                        <img
                          src="assets/images/Gear-shift-smooth.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        transmission type{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/Color.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        color{" "}
                      </td>
                    </tr>
                    <tr>
                      <th>{detail?.body_shape}</th>
                      <th>{detail?.fuel_type}</th>
                      <th>{detail?.transmission}</th>
                      <th>
                        <span
                          title={detail?.colourDetails?.name}
                          style={{
                            backgroundColor: `${
                              detail?.colourDetails?.code
                                ? detail?.colourDetails?.code
                                : "NA"
                            }`,
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            display: " block",
                            boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
                          }}
                        ></span>
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">Listing Details</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/Price.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        price
                      </td>
                      <td>
                        <img
                          src="assets/images/Price.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        price
                      </td>
                      <td>
                        <img
                          src="assets/images/How-soon-wants-to-sell.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        How soon wants to sell{" "}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {currencies.format(detail?.price, {
                          code: "INR",
                          symbol: "INR ",
                        })}
                      </th>
                      <th>{detail?.price_type}</th>
                      <th>{detail?.wants_to_sell_soon}</th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">Service History</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/KM-Reading.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        KM Reading
                      </td>
                      <td>
                        <img
                          src="assets/images/KM-reading-genuine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        KM reading genuine
                      </td>
                      <td>
                        <img
                          src="assets/images/Gear-Type.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        last service done at KM{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/Accidental-details.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Accidental details{" "}
                      </td>
                    </tr>
                    <tr>
                      <th>{detail?.km_meter_reading} km</th>
                      <th>
                        <img
                          src={
                            detail?.is_reading_genuine === "yes"
                              ? "assets/images/yes.png"
                              : detail?.is_reading_genuine === "no"
                              ? "assets/images/no.png"
                              : detail?.is_reading_genuine === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>{detail?.service_done_at_what_km}</th>
                      <th>{detail?.accidental_status}</th>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h3 className="mb-5 pt-3 theme-color">Features</h3>
              <div className="table-responsive">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">Convenience</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/Power-Windows-Working-Fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Power Windows
                      </td>
                      <td>
                        <img
                          src="assets/images/Seat-Covers-Available.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        seat height adjustment
                      </td>
                      <td>
                        <img
                          src="assets/images/ORVM-Working-Fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        auto fold ORVM{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/push-button.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        push button start
                      </td>
                      <td>
                        <img
                          src="assets/images/cruise-control.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        cruise control
                      </td>
                      <td>
                        <img
                          src="assets/images/Wipers-Working-Fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        real wiper
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.features_details?.power_windows === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.power_windows === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.power_windows ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.seat_height_adjustment ===
                            "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details
                                  ?.seat_height_adjustment === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details
                                  ?.seat_height_adjustment === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.auto_fold_orvm === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.auto_fold_orvm ===
                                "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.auto_fold_orvm ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.push_button_start ===
                            "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.push_button_start ===
                                "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.push_button_start ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.cruise_control === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.cruise_control ===
                                "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.cruise_control ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.rear_wiper === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.rear_wiper === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.rear_wiper ===
                                "normal"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4" colSpan="6">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/navigation-available.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        navigation available
                      </td>
                      <td>
                        <img
                          src="assets/images/AC+Heater-Working.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        auto AC{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/USB-charger.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        USB charger
                      </td>
                      <td>
                        <img
                          src="assets/images/sunroof.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        sunroof
                      </td>
                      <td>
                        <img
                          src="assets/images/Seat-Covers-Available.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Seat Covers
                      </td>
                      <td>
                        <img
                          src="assets/images/AC+Heater-Working.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        second row AC
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.features_details?.navigation_available ===
                            "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details
                                  ?.navigation_available === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details
                                  ?.navigation_available === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.auto_ac === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.auto_ac === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.auto_ac === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.usb_charger === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.usb_charger === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.usb_charger ===
                                "normal"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.sunroof === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.sunroof === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.sunroof === "not sure"
                              ? "assets/images/not-readable.png"
                              : "na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.seat_cover === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.seat_cover === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.seat_cover ===
                                "normal"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.second_row_ac === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.second_row_ac === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.second_row_ac ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">entertainment</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/stereo.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        stereo
                      </td>
                      <td>
                        <img
                          src="assets/images/Stereo+Speaker-Working-fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Speakers{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/base-tube.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        base tube
                      </td>
                      <td>
                        <img
                          src="assets/images/Steering-Control-All-working-fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Steering mounted audio control
                      </td>
                      <td>
                        <img
                          src="assets/images/bluetooth.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        bluetooth
                      </td>
                      <td>
                        <img
                          src="assets/images/push-button.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        touchscreen
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.features_details?.stereo === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.stereo === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.stereo === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.speakers === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.speakers === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.speakers ===
                                "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.bass_tube === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.bass_tube === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.bass_tube ===
                                "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details
                              ?.steering_mounted_audio_control === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details
                                  ?.steering_mounted_audio_control === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details
                                  ?.steering_mounted_audio_control ===
                                "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.bluetooth === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.bluetooth === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.bluetooth ===
                                "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.touchscreen === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.touchscreen === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.touchscreen ===
                                "normal"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">performance</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/bharat-stage-norm.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        bharat stage norm
                      </td>
                      <td>
                        <img
                          src="assets/images/Fuel.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        fuel efficiency KM per liter{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/turbo.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        turbo
                      </td>
                      <td>
                        <img
                          src="assets/images/Gear-Type.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        no. of gears
                      </td>
                      <td>
                        <img
                          src="assets/images/4X4.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        4X4
                      </td>
                      <td>
                        <img
                          src="assets/images/Wheel-Noise-Wobbling.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        alloys wheels
                      </td>
                    </tr>
                    <tr>
                      <th>{detail?.features_details?.bharat_stage_norm}</th>
                      <th>
                        {detail?.features_details?.fuel_efficiency_km_per_lt}
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.is_turbo === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.is_turbo === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.is_turbo ===
                                "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>{detail?.features_details?.no_of_gears}</th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.is_4x4 === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.is_4x4 === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.is_4x4 === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.is_alloys_wheels === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.is_alloys_wheels ===
                                "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.is_alloys_wheels ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">safety</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/central-locking-with-remote.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        central locking with remote
                      </td>
                      <td>
                        <img
                          src="assets/images/central-locking-with-remote.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        child lock{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/Braking-Fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        aBS
                      </td>
                      <td>
                        <img
                          src="assets/images/reverse-parking-sensor.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        reverse parking sensor
                      </td>
                      <td>
                        <img
                          src="assets/images/reverse-camera.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        reverse camera
                      </td>
                      <td>
                        <img
                          src="assets/images/Airbag-Ever-Opened.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        airbags
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.features_details
                              ?.central_locking_with_remote === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details
                                  ?.central_locking_with_remote === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details
                                  ?.central_locking_with_remote === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.child_lock === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.child_lock === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.child_lock ===
                                "normal"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.abs === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.abs === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.abs === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.reverse_parking_sensor ===
                            "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details
                                  ?.reverse_parking_sensor === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details
                                  ?.reverse_parking_sensor === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.reversing_camera === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.reversing_camera ===
                                "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.reversing_camera ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.airbags === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.airbags === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.airbags === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/AC+Heater-Working.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        defogger
                      </td>
                      <td>
                        <img
                          src="assets/images/All-Lights-Working-fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        fog lamps{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/All-Lights-Working-fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        daynight running lamp
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.features_details?.defogger === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.defogger === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.defogger ===
                                "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.fog_lamps === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.fog_lamps === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.fog_lamps ===
                                "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.drl === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.drl === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.drl === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h3 className="mb-5 pt-3 theme-color">Loose Items & Docx</h3>
              <div className="table-responsive">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">docx</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/original-RC.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        original RC available
                      </td>
                      <td>
                        <img
                          src="assets/images/valid-insurance.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        valid insurance available
                      </td>
                      <td>
                        <img
                          src="assets/images/bank-noc.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        bank NOC available
                      </td>
                      <td>
                        <img
                          src="assets/images/original-RC.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        previous service bills
                      </td>
                      <td>
                        <img
                          src="assets/images/Gear-Type.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        service booklet
                      </td>
                      <td>
                        <img
                          src="assets/images/extended-warranty.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        extended warranty
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details
                              ?.original_rc_available === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details
                                  ?.original_rc_available === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details
                                  ?.original_rc_available === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details
                              ?.valid_insurance_available === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details
                                  ?.valid_insurance_available === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details
                                  ?.valid_insurance_available === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details?.bank_noc_available ===
                            "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details
                                  ?.bank_noc_available === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details
                                  ?.bank_noc_available === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details
                              ?.previous_service_bills === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details
                                  ?.previous_service_bills === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details
                                  ?.previous_service_bills === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details?.service_booklet ===
                            "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details?.service_booklet ===
                                "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details?.service_booklet ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details
                              ?.extended_warrenty_valid === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details
                                  ?.extended_warrenty_valid === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details
                                  ?.extended_warrenty_valid === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4" colSpan="6">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/pollution-certification.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        pollution certification
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details
                              ?.pollution_ccertificate_valid === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details
                                  ?.pollution_ccertificate_valid === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details
                                  ?.pollution_ccertificate_valid === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">loose items</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/password.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        second key available
                      </td>
                      <td>
                        <img
                          src="assets/images/central-locking-with-remote.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        remote for central locking{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/valid-insurance.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        HSRP
                      </td>
                      <td>
                        <img
                          src="assets/images/Wheel-Noise-Wobbling.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        spare tyre available
                      </td>
                      <td>
                        <img
                          src="assets/images/jack-tool-kit.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        jack & tool kit available
                      </td>
                      <td>
                        <img
                          src="assets/images/stereo.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        stereo remote
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details?.second_key_available ===
                            "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details
                                  ?.second_key_available === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details
                                  ?.second_key_available === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details
                              ?.remote_for_central_locking === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details
                                  ?.remote_for_central_locking === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details
                                  ?.remote_for_central_locking === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details?.hsrp_available === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details?.hsrp_available ===
                                "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details?.hsrp_available ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details?.spare_type_available ===
                            "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details
                                  ?.spare_type_available === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details
                                  ?.spare_type_available === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details
                              ?.jack_and_tool_kit_available === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details
                                  ?.jack_and_tool_kit_available === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details
                                  ?.jack_and_tool_kit_available === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details?.stereo_remote === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details?.stereo_remote ===
                                "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details?.stereo_remote ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/antenna.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        antenna
                      </td>
                      <td>
                        <img
                          src="assets/images/amplifier.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        amplifier{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/AC+Heater-Working.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        AC remote
                      </td>
                      <td>
                        <img
                          src="assets/images/Wheel-Noise-Wobbling.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        4 wheel caps
                      </td>
                      <td>
                        <img
                          src="assets/images/Gear-Type.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        gear lock pin
                      </td>
                      <td>
                        <img
                          src="assets/images/password.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        gear lock key
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details?.antenna === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details?.antenna === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details?.antenna ===
                                "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details?.amplifier === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details?.amplifier === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details?.amplifier ===
                                "normal"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details?.ac_remote === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details?.ac_remote === "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details?.ac_remote ===
                                "normal"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details?.is_4_wheel_caps ===
                            "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details?.is_4_wheel_caps ===
                                "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details?.is_4_wheel_caps ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details?.gear_lock_pin === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details?.gear_lock_pin ===
                                "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details?.gear_lock_pin ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.loose_item_details?.gear_lock_key === "yes"
                              ? "assets/images/yes.png"
                              : detail?.loose_item_details?.gear_lock_key ===
                                "no"
                              ? "assets/images/no.png"
                              : detail?.loose_item_details?.gear_lock_key ===
                                "not"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-sm-12 mb-4">
            <h2>Appraisal Details</h2>
          </div>
          <div className="col-sm-12 mb-4">
            <div className="view-detail mt-0">
              <div className="table-responsive">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">exterior</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/KM-reading-genuine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        tyre last changed KM reading
                      </td>
                      <td>
                        <img
                          src="assets/images/Wheel-Noise-Wobbling.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        all tyre same brand
                      </td>
                      <td>
                        <img
                          src="assets/images/Chasis-Engine-Tracing-match.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Chasis Engine Tracing match
                      </td>
                      <td>
                        <img
                          src="assets/images/Dent-Paint-Required.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Dent Paint Required
                      </td>
                      <td>
                        <img
                          src="assets/images/Rusting-on-any-panel.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Rusting on any panel
                      </td>
                      <td>
                        <img
                          src="assets/images/Accidental-details.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Accidental history
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {detail?.physical_appraisal_details
                          ?.tyre_last_changed_km_reading_value
                          ? detail?.physical_appraisal_details
                              ?.tyre_last_changed_km_reading_value
                          : detail?.physical_appraisal_details
                              ?.tyre_last_changed_km_reading_approx
                          ? detail?.physical_appraisal_details
                              ?.tyre_last_changed_km_reading_approx
                          : detail?.physical_appraisal_details
                              ?.tyre_last_changed_km_reading_value}
                      </th>
                      {/* <th>
                        {
                          detail?.physical_appraisal_details
                            ?.tyre_last_changed_km_reading_value
                        }
                      </th> */}
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.all_tyre_same_brand === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.all_tyre_same_brand === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.all_tyre_same_brand === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.engine_tracing_match === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.engine_tracing_match === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.engine_tracing_match === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        {detail?.physical_appraisal_details?.paint_required}
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.rusting_on_any_panel === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.rusting_on_any_panel === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.rusting_on_any_panel === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>{detail?.accidental_status}</th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">interior/cockpit</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/AC+Heater-Working.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        AC+Heater Working
                      </td>
                      <td>
                        <img
                          src="assets/images/Seat-Covers-Available.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Seat Covers Available
                      </td>
                      <td>
                        <img
                          src="assets/images/Airbag-Ever-Opened.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Airbag Ever Opened
                      </td>
                      <td>
                        <img
                          src="assets/images/Dashboard-Warning-lights-any.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Dashboard Warning lights any
                      </td>
                      <td>
                        <img
                          src="assets/images/Steering-Control-All-working-fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Steering Control All working fine
                      </td>
                      <td>
                        <img
                          src="assets/images/Horn-Working-Fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Horn Working Fine
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.ac_heater_working_fine === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.ac_heater_working_fine === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.ac_heater_working_fine === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.features_details?.seat_cover === "yes"
                              ? "assets/images/yes.png"
                              : detail?.features_details?.seat_cover === "no"
                              ? "assets/images/no.png"
                              : detail?.features_details?.seat_cover ===
                                "normal"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.airebag_ever_opened === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.airebag_ever_opened === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.airebag_ever_opened === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.dashboard_warning_lights_any === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.dashboard_warning_lights_any === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.dashboard_warning_lights_any === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.steering_controll_all_working_fine === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.steering_controll_all_working_fine === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.steering_controll_all_working_fine ===
                                "normal"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.horn_working_fine === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.horn_working_fine === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.horn_working_fine === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">electrical/electronic</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/Power-Windows-Working-Fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Power Windows Working Fine
                      </td>
                      <td>
                        <img
                          src="assets/images/Wipers-Working-Fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Wipers Working Fine{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/ORVM-Working-Fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        ORVM Working Fine
                      </td>
                      <td>
                        <img
                          src="assets/images/Battery-Last-Changed-Date.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Battery Last Changed Date
                      </td>
                      <td>
                        <img
                          src="assets/images/All-Lights-Working-fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        All Lights Working fine
                      </td>
                      <td>
                        <img
                          src="assets/images/Stereo+Speaker-Working-fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Stereo + Speaker Working fine
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.power_windows_working_fine === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.power_windows_working_fine === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.power_windows_working_fine === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.wipers_working_fine === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.wipers_working_fine === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.wipers_working_fine === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.orvm_working_fine === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.orvm_working_fine === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.orvm_working_fine === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        {moment(
                          detail?.physical_appraisal_details
                            ?.last_date_of_battery_changed
                        ).isValid()
                          ? moment(
                              detail?.physical_appraisal_details
                                ?.last_date_of_battery_changed
                            ).format("DD MMM YYYY")
                          : detail?.physical_appraisal_details
                              ?.last_date_of_battery_changed}
                      </th>
                      {/* <th>
                        {
                          detail?.physical_appraisal_details
                            ?.last_date_of_battery_changed
                        }
                      </th> */}
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.all_lights_working === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.all_lights_working === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.all_lights_working === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.stereo_speaker_working === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.stereo_speaker_working === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.stereo_speaker_working === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">engine/gear box ralated</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/Abnormal-Noise-any.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Abnormal Noise any
                      </td>
                      <td>
                        <img
                          src="assets/images/Engine-repaired-ever.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Engine repaired ever{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/Gear-shift-smooth.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Gear shift smooth
                      </td>
                      <td>
                        <img
                          src="assets/images/Engine-repaired-ever.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        any engine oil leakage
                      </td>
                      <td>
                        <img
                          src="assets/images/Abnormal-Noise-any.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        any oil back throw
                      </td>
                      <td>
                        <img
                          src="assets/images/Exhaust-smoke.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Exhaust smoke
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.abnormal_noise === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.abnormal_noise === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.abnormal_noise === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.engine_repaired_ever === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.engine_repaired_ever === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.engine_repaired_ever === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.gear_shift_smooth === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.gear_shift_smooth === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.gear_shift_smooth === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.any_oil_leakage === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.any_oil_leakage === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.any_oil_leakage === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.any_oil_back_throw === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.any_oil_back_throw === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.any_oil_back_throw === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        {detail?.physical_appraisal_details?.exhaust_smoke}
                      </th>
                      {/* <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.exhaust_smoke === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.exhaust_smoke === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.exhaust_smoke === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th> */}
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <h5 className="theme-color">road test result</h5>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/Abnormal-Noise-any.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        starting noise/delay
                      </td>
                      <td>
                        <img
                          src="assets/images/Engine-repaired-ever.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Engine missing{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/FanBelt-Noise.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        FanBelt Noise
                      </td>
                      <td>
                        <img
                          src="assets/images/Suspension-Noise.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Suspension Noise
                      </td>
                      <td>
                        <img
                          src="assets/images/Steering-Noise.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Steering Noise
                      </td>
                      <td>
                        <img
                          src="assets/images/Wheel-Noise-Wobbling.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Wheel Noise Wobbling
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.starting_noise_delay === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.starting_noise_delay === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.starting_noise_delay === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.engine_missing === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.engine_missing === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.engine_missing === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.fanbelt_noise === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.fanbelt_noise === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.fanbelt_noise === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.suspension_noise === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.suspension_noise === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.suspension_noise === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.steering_noise === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.steering_noise === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.steering_noise === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.wheel_noise_wobbiling === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.wheel_noise_wobbiling === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.wheel_noise_wobbiling === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                    <tr>
                      <td className="py-4 pl-0" colSpan="6">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="assets/images/Clutch-Spongy.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Clutch Spongy
                      </td>
                      <td>
                        <img
                          src="assets/images/Braking-Fine.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Braking Fine{" "}
                      </td>
                      <td>
                        <img
                          src="assets/images/Abnormal-Heating.png"
                          className="detail-icon"
                          alt=""
                        />{" "}
                        Abnormal Heating
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.clutch_spongy === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.clutch_spongy === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.clutch_spongy === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.breaking_fine === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.breaking_fine === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.breaking_fine === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                      <th>
                        <img
                          src={
                            detail?.physical_appraisal_details
                              ?.abnormal_heating === "yes"
                              ? "assets/images/yes.png"
                              : detail?.physical_appraisal_details
                                  ?.abnormal_heating === "no"
                              ? "assets/images/no.png"
                              : detail?.physical_appraisal_details
                                  ?.abnormal_heating === "not sure"
                              ? "assets/images/not-readable.png"
                              : "assets/images/na-img.png"
                          }
                          width="20px"
                          alt=""
                        />
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {error && (
            <div className="d-flex justify-content-end w-100">
              <div
                className={
                  success ? "alert alert-success" : "alert alert-danger"
                }
              >
                {error}
              </div>
            </div>
          )}
          {/* <div className="col-sm-12">
            <div className="view-btns">
              <button
                className="btn btn-cancel mt-2"
                onClick={() => {
                  handleShow();
                }}
              >
                <img
                  src="assets/images/delete-button.png"
                  alt=""
                  width="53px"
                />
              </button>
              <button
                className="btn btn-reject btn-view mt-2"
                onClick={() => {
                  approvedReject({
                    status: 2,
                    car_id: String(detail?.id),
                  });
                }}
              >
                Reject
              </button>
              <button
                className="btn btn-accept btn-view mt-2"
                onClick={() => {
                  approvedReject({
                    status: 1,
                    car_id: String(detail?.id),
                  });
                }}
              >
                Accept
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <DeleteModal
        page="Car"
        show={show}
        handleShow={handleShow}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default CarDetail;
